import React from "react";
import { PageLayout } from "~components/templates/page-layout";
import { withContext } from "~context";
import * as styles from "./index.module.scss";

const Component: React.FC = () => {
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{ title: "プライバシーポリシー" }}
    >
      <div className={styles.PrivacyPolicy}>
        <h1>プライバシーポリシー</h1>
        <p>
          {`株式会社Flatt Security（以下「当社」といいます。）は、https://flatt.tech（以下「本ウェブサイト」といいます。）上及び当社が提供するサービス（以下「当社サービス」といいます。）並びに当社の採用活動における、本ウェブサイト及び当社サービスのユーザー（以下単に「ユーザー」といい、当社サービスのユーザーに限る場合には「当社サービスのユーザー」といいます。）及び当社の採用候補者（以下「候補者」といい、総称して「ユーザー等」といいます。）の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。

第1条（個人情報）
「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号、以下「個人情報保護法」といいます。）にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの、又は、個人識別符号が含まれるものを指します。

第2条（個人情報の収集方法）
当社は、ユーザー等が本ウェブサイトの問い合わせ機能を利用する際、及び当社サービスをご利用いただく際に、氏名、生年月日、メールアドレスなどの個人情報の提供を求めることがあります。また、ユーザー等と当社の提携先との間でなされたユーザー等の個人情報を含む情報を、提携先から収集することがあります。

第3条（個人情報を収集・利用する目的）
当社が個人情報を収集・利用する目的は、以下のとおりです。当社は、当該目的の範囲内に限り、個人情報を収集・利用するものとします。利用目的の範囲を超えて個人情報を利用する場合には、事前に適切な方法でユーザー等ご本人からの同意を得るものとします。
(1)当社が自ら取得する個人情報
  (i) 当社サービスのユーザーに関する個人情報
    ・当社サービスに関する契約手続、当社サービスのユーザー管理及びこれらに付随する業務の遂行のため
    ・当社サービスのユーザーからのお問合せに回答するため
    ・当社サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内並びにメールマガジン送信その他の販売促進施策のため
    ・当社サービスのセキュリティ確保上必要な場合又は事件・事故が発生した場合の調査、ご報告又はご連絡のため
    ・メンテナンス情報、規約の変更その他の重要なご連絡等、当社サービスを運用する上で必要なご連絡のため
    ・利用規約に違反した当社サービスのユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定・連絡のため
    ・当社サービスのユーザーに自身の登録情報の確認や変更、削除、ご利用状況の確認を行っていただくため
    ・本人確認や法的手続を行うため
    ・取材、アンケート、キャンペーン又はモニター等の依頼のため
    ・当社又は当社サービスに関するご意見・お問い合わせへの回答のため
    ・上記の利用目的に付随関連する目的のため
  (ii) 資料請求、トライアルへのご登録、その他当社サービス等に関するお問い合わせを頂いた方に関する個人情報
    ・当社サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内並びにメールマガジン送信その他の販売促進施策のため
    ・当社のサービスのセキュリティ確保上必要な場合又は事件・事故が発生した場合の調査、ご報告又はご連絡のため
    ・メンテナンス情報、規約の変更その他の重要なご連絡等、当社のサービスを運用する上で必要なご連絡のため
    ・利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定・連絡のため
    ・当社又は当社サービスに関するご意見・お問い合わせへの回答のため
    ・上記の利用目的に付随関連する目的のため
  (iii) イベント又はセミナー等の参加者様に関する個人情報
    ・イベント・セミナー等の運営管理のため
    ・アンケートの送付及び管理のため
    ・当社サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内並びにメールマガジン送信その他の販売促進施策のため
    ・当社又は当社のサービスに関するご意見・お問い合わせへの回答のため
・上記の利用目的に付随関連する目的のため
  (iv) 当社の採用活動に応募頂いた方に関する個人情報
    ・候補者に対し当社の採用活動に関する情報を提供するため
    ・候補者からの問い合わせに対して対応するため
    ・候補者の応募又は雇用機会に関する連絡をするため
    ・採用活動に際して、候補者の適性を評価するため
    ・当社の採用プロセスを改善するため
    ・候補者の情報を適切に管理するため
    ・候補者についての情報を当社の将来の採用活動及び採用計画のためのデータ又は統計データとして活用するため
    ・候補者と当社との間で雇用契約を締結する場合、当該雇用契約締結に必要な手続を行うため
    ・候補者が当社に入社した場合、候補者の雇用管理を行うため

(2)当社が受託業務によって取得する個人情報
  (i) 当社サービスのユーザーへの当社サービス提供に伴い、取扱いの一部又は全部の受託のために当社サービスのユーザーからお預かりする個人情報
    ・当社サービスの提供・運営その他当社サービスに関する当社の義務の履行及び管理を行うため
    ・当社サービスのユーザーからのお問合せに回答するため
    ・当社サービスのセキュリティ確保上必要な場合又は事件・事故が発生した場合の調査、ご報告又はご連絡のため
    ・メンテナンス情報、規約の変更その他の重要なご連絡等、当社サービスを運用する上で必要なご連絡のため
    ・利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定・連絡のため
    ・本人確認や法的手続を行うため
    ・当社又は当社サービスに関するご意見・お問い合わせへの回答のため
    ・上記の利用目的に付随関連する目的のため

第4条（個人情報の第三者提供）
1. 当社は、次の各号に掲げる場合及び個人情報保護法その他の法令で認められる場合を除いて、あらかじめユーザー等の同意を得ることなく、第三者に個人情報を提供することはありません。
2. 前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
(1)当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
(2)合併その他の事由による事業の承継に伴って個人情報が提供される場合
(3)個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称及び住所並びに法人にあってはその代表者の氏名について、あらかじめ本人に通知し、又は本人が容易に知り得る状態に置いた場合

第5条（個人情報の開示）
1. 当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1，000円の手数料を申し受けます。
(1)本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
(2)当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
(3)その他法令に違反することとなる場合
2. 履歴情報及び特性情報等の個人情報以外の情報については、原則として開示いたしません。

第6条（個人情報の訂正及び削除）
1. ユーザー等は、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加又は削除（以下「訂正等」といいます。）を請求することができます。
2. 当社は、ユーザー等本人から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。
3. 当社は、前項の規定に基づき訂正等を行った場合、又は訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザー等本人に通知するものとします。

第7条（個人情報の利用停止等）
1. 当社は、本人から、個人情報が、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
2. 前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。
3. 当社は、前項の規定に基づき利用停止等を行った場合、又は利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザー等に通知します。
4. 前二項の規定にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザー等の権利利益を保護するために必要なこれに代わるべき措置を取ることが可能な場合は、当社は、かかる代替措置を講じることができるものとします。

第8条（その他の情報の取得等）
1. 本ウェブサイト、及び当社が提供するいくつかのサービスでは、ログファイルの収集、クッキーの送信、ウェブビーコンの設置などによりユーザー等のIPアドレス、アクセス回数、ご利用ブラウザ、OSその他利用端末などの情報を収集しています。
2.本ウェブサイト、及び当社が提供するいくつかのサービスでは、Google社によるアクセス解析ツール「Googleアナリティクス」を使用し、クッキーの送信を行っています。このデータは匿名で収集されており、個人を特定するものではありません。このクッキーの送信はユーザー等が利用するブラウザでクッキーを無効にすることで収集を拒否することが出来ます。このクッキーの送信の詳細は、以下のURLから確認することができます。
https://policies.google.com/technologies/partner-sites?hl=ja

第9条（本ポリシーの変更）
1. 本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザー等に通知することなく、変更することができるものとします。
2.当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
3. 当社は、保有する個人情報に関して適用される日本の法令その他の規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めるものとします。

第10条（お問い合わせ窓口）
本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
住所：東京都文京区本郷3-43-16 コア本郷ビル2A
代表者：井手康貴
社名：株式会社Flatt Security
Eメールアドレス：contact@flatt.tech

2019年6月2日 制定
2020年10月24日 改定
2022年3月31日 改定
`}
        </p>
      </div>
    </PageLayout>
  );
};

export default withContext(Component);
